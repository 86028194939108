import React from 'react'
import { Typography, Card } from '@mui/material';
import Layout from '../layouts';

const AboutPage = () => (
    <Layout pageName="About">
        <Card style={{padding:'20px'}}>
            <Typography variant="h6" style={{color:'#080',fontWeight:'bold'}}>Synergy Consulting and Training (SCT) was founded by Helene Cho in 1993.</Typography>

            <p>For over twenty years, SCT has assisted <b>hundreds of clients</b> to maximize their
                effectiveness. We have worked with <b>corporations, non-profits, academia, government agencies</b>,
                and more. We have experience with just about every industry, including <b>technology, health care,
                    manufacturing, retail, hospitality, and finance</b>.</p>
            <p><b>Mission:</b> Assist organizations and individuals to achieve excellence through innovative
                programs that focus on results.</p>
            <p><b>Vision:</b> Healthy, productive, empowered organizations and individuals who joyfully and
                effectively contribute to their stakeholders, communities, and the world.</p>

            <p><b>Key Values:</b></p>
            <ul>
                <li>Results</li>
                <li>Integrity</li>
                <li>Innovation</li>
                <li>Respect</li>
                <li>Humor</li>
            </ul>

            <div>
                <blockquote style={{color:'#080',maxWidth:'400px',margin:'0 auto',textAlign:'center'}}>"If you are
                    coming to help me, you are wasting your time. But if you are coming because
                    your liberation is bound up with mine, then let us work together."
                    <br/><br/><small><em>- Australian Aboriginal Woman</em></small></blockquote>
            </div>

        </Card>
    </Layout>
);

export default AboutPage;
